<template>
  <el-dialog title="【查看】异常管理" :close-on-click-modal="false" width="30%" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" size="small" label-width="100px">
      <el-form-item label="异常类型" prop="exceptionType.name">
        <el-input v-model="dataForm.exceptionType.name" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="建议处理方案" prop="exceptionType.programme">
        <el-input v-model="dataForm.exceptionType.programme" :disabled="true" type="textarea" :rows="3"></el-input>
      </el-form-item>
      <el-form-item label="异常说明" prop="explainDesc">
        <el-input v-model="dataForm.explainDesc" :disabled="true" type="textarea" :rows="3"></el-input>
      </el-form-item>
      <el-form-item label="附件" prop="carriageWidth">
        <el-image
          v-for="item in dataForm.enclosureInfoList"
          :key="item.id"
          :value="item.id"
          style="width: 150px; height: 150px"
          :src="item.url">
        </el-image>
      </el-form-item>
      <el-form-item label="异常处理状态" prop="status">
        <el-select class="item-choose" v-model="dataForm.status" :disabled="isShow">
          <el-option
            v-for="item in dictTypeMap.abnormalStatus"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="异常处理说明" prop="solution">
        <el-input v-model="dataForm.solution" :disabled="isShow" type="textarea" :rows="3"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="!isShow">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      isShow: true,
      dataForm: {
        id: null,
        explainDesc: null,
        solution: null,
        status: 1,
        waybillStatus: null,
        exceptionType: {
          id: null,
          name: null,
          programme: null
        },
        enclosureInfoList: []
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    init (row, todo) {
      this.isShow = (todo === 'view')
      this.visible = true
      this.$http({
        url: this.$http.adornUrl(`/orderException/${row.id}`),
        method: 'GET',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dataForm = data
        this.dataForm.status = 1
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$http({
        url: this.$http.adornUrl(`/orderException/update`),
        method: 'POST',
        data: this.$http.adornData({
          'id': this.dataForm.id,
          'solution': this.dataForm.solution,
          'status': this.dataForm.status
        })
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
    }
  }
}
</script>
